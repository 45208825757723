import React, { useContext, useEffect, useState } from "react";

import { Container, Row, Col, Button, Collapse } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import TransShowCase from "../components/TransShowCase";
import Differentiation from "../components/Differentiation";
import TransFeatures from "../components/TransFeatures";
import TransDashboard from "../components/TransDashboard";

import objectHero from "../assets/extra/Products/transaction-categorizer.svg";

import ogImage from "../assets/extra/global/link-logo-white.png";

import Seo from "../components/Seo.js";
import TransCTA from "../components/TransCTA";
import TxnApiDemo from "../components/APIDemos/TxnApiDemo";
import GlobalContext from "../context/GlobalContext";
import BeforeAfterImage from "../components/BeforeAfterImage/BeforeAfterImage";
import TxnDataDiff from "../components/Differentiationv2/TxnDataDiff/TxnDataDiff";
import TxnDataUsecases from "../components/Usecasesv2/TxnDataUsecases/TxnDataUsecases";
import CategorizationAd from "../components/CategorizationAd/CategorizationAd";
import TxnApiDemoV2 from "../components/APIDemos/TxnApiDemov2";
import APIDemo from "../components/APIDemos/APIDemo";
import WhyGenify from "../components/Differentiationv2/WhyGenify";
import { Link } from "gatsby";

const TxnCategorizer = () => {
  // const url = "http://www.genify.ai/api/v1.0/generic/?model_id=1&description=starbucks&amount=45";
  // const bearer = 'Bearer' + "uooqTdoyXHV9iC8hor7WtcQQPbHFRSc1fI8ifiwcj7";
  const [openItem, setOpenItem] = useState(1);
  const imgurl = `https://genify.ai${ogImage}`;

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/recommendation-api",
        page_type: "productPage",
        event: "page_view",
        title: "Transaction Categorization API",
      });
    }
  }, []);
  //   const getData = () => {
  //     fetch(
  //       "https://www.genify.ai/api/v1.0/classifier?description=starbucks&amount=45",
  //       {
  //         headers: {
  //           Authorization: "Bearer uooqTdoyXHV9iC8hor7WtcQQPbHFRSc1fI8ifiwcj7",
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => console.log(data));
  //   };

  return (
    <>
      <Seo
        title="Transaction Categorization API | Genify"
        // description='A ML-based categorizer that allows for the automatic classification of digital transactions.'
        description="Transaction categorization & enrichment API enable banks and fintechs to categorize credit card and other banking transactions. Click for more."
        image={imgurl}
        url="https://www.genify.ai/recommendation-api/"
      />

      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null",
          path: "productPage",
        }}
        footerConfig={{
          style: "style1",
        }}
      >
        {/* <div style={{ paddingTop: '5%', backgroundColor: '#fff' }}></div> */}
        <div
          className=" dark-mode-texts pt-13 pt-md-25 pb-13 pb-md-25 mx-md-6 rounded-10 "
          style={{ backgroundColor: "#fff" }}
        >
          <>
            {/* <!-- Hero Area --> */}
            <div
              className="hero-area position-relative  pt-29 pt-lg-33  pb-lg-35 pb-lg-35"
              style={{ backgroundColor: "#fff" }}
            >
              <Container className="landing-large">
                <Row className="justify-content-center  align-items-center">
                  <Col
                    lg="6"
                    md="15"
                    sm="15"
                    xs="15"
                    className="order-lg-2 mt-9 mt-lg-0 pl-md-25"
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-once="true"
                  >
                    <div
                      className="hero-content dark-mode-texts landing-text"
                      style={{ width: "90%" }}
                    >
                      <h1
                        className="title gr-text-2 mb-7"
                        style={{ color: "rgb(74, 99, 165)" }}
                      >
                        Transaction Categorization API{" "}
                      </h1>

                      <p
                        className="gr-text-9 pr-5 pl-0 pr-xl-11 py-10"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        With our transaction categorization and enrichment API,
                        you can categorize credit card and account transactions
                        in the most granular and accurate way. Enrich
                        transactions with all the information your users expect
                        in their bank or fintech app.
                      </p>
                      <div
                        className="hero-btn pb-10"
                        style={{ zIndex: "9999", textAlign: "left" }}
                      >
                        <Link
                          to="/contact-us"
                          className="gr-text-color gr-hover-text-white"
                        >
                          <Button
                            // href="/contact-us/#contactform"
                            className="gr-hover-y"
                            style={{ backgroundColor: "#ff565b", border: "0" }}
                            onClick={() =>
                              window.dataLayer.push({
                                page_type: "productPage",
                                event: "contact_us",
                              })
                            }
                          >
                            Talk to us{" "}
                          </Button>
                        </Link>

                        <Link
                          to="/txn-data-gui"
                          className="gr-text-color gr-hover-text-white"
                        >
                          <Button
                            onClick={() =>
                              window.dataLayer.push({
                                page_type: "productPage",
                                event: "try_api",
                              })
                            }
                            // href="/txn-data-gui"
                            className="gr-hover-y mt-sm-0 ml-lg-3 mt-md-0 mt-lg-0 ml-sm-10 mt-sm-0 mt-xs-0 mt-5"
                            style={{
                              backgroundColor: "rgb(74, 99, 165)",
                              border: "0",
                              // marginLeft: "10px",
                            }}
                          >
                            Try API{" "}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="15"
                    sm="15"
                    xs="15"
                    className="order-lg-1 order-sm-1 order-xs-1  "
                  >
                    <div
                      className="test-landing"
                      data-aos="fade-left"
                      data-aos-duration="750"
                      data-aos-delay="500"
                    >
                      <div style={{  margin: "auto", }}>
                        <BeforeAfterImage />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
          <div>
            <CategorizationAd />
          </div>
          <div
            style={{ backgroundColor: "#fff" }}
            className="pt-15 pt-lg-25 blue-drawer pb-25"
          >
            <Container>
              <Row className="align-items-center">
                <Col
                  md="10"
                  lg="12"
                  xl="6"
                  className="offset-xl-1 drawer mt-9 mt-lg-0 order-lg-2 order-md-2 order-sm-2 order-xs-2"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  // style={{ maxWidth: "40vw" }}
                >
                  <div
                    className="accordion pl-9 pr-8 rounded-10  border-top-5"
                    id="accordionExample"
                    style={{
                      backgroundColor: "rgb(74, 99, 165)",
                      borderBlockColor: "#ff565b",
                    }}
                  >
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-1">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left  px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          aria-controls="collapse2-1"
                          onClick={() => setOpenItem(1)}
                          aria-expanded={openItem === 1}
                          style={{ color: "#fff" }}
                        >
                          Category
                        </button>
                      </div>

                      <Collapse in={openItem === 1}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Categorize transactions into more than 70 expense
                            and income categories.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-2">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          data-target="#collapse2-2"
                          onClick={() => setOpenItem(2)}
                          aria-expanded={openItem === 2}
                        >
                          Merchant Logo
                        </button>
                      </div>

                      <Collapse in={openItem === 2}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Make your users feel like home. Display logos of
                            merchants in your banking app instead of dull,
                            generic icons.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-2">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          data-target="#collapse2-2"
                          onClick={() => setOpenItem(3)}
                          aria-expanded={openItem === 3}
                        >
                          Clean Merchant Name
                        </button>
                      </div>

                      <Collapse in={openItem === 3}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Do users enjoy deciphering transaction descriptions?
                            Look no further.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-2">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          data-target="#collapse2-2"
                          onClick={() => setOpenItem(4)}
                          aria-expanded={openItem === 4}
                        >
                          Merchant Website
                        </button>
                      </div>

                      <Collapse in={openItem === 4}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Link each expense with a website, for a sense of
                            completeness and control.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-3">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100"
                          type="button"
                          data-target="#collapse2-3"
                          onClick={() => setOpenItem(5)}
                          aria-expanded={openItem === 5}
                        >
                          Carbon Footprint
                        </button>
                      </div>

                      <Collapse in={openItem === 5}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Tell your users the carbon footprint of every single
                            transaction. Contribute to environmental awareness,
                            one transaction at a time.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="5"
                  className="order-lg-1 order-md-1 order-sm-1 order-xs-1 mt-sm-20"
                  style={{ order: "-1" }}
                >
                  <div className=" mt-12 mt-md-0">
                    <div className="info-label">Data Enrichment</div>
                    <h2
                      className="gr-text-4 mb-7 justify-content-center"
                      style={{ color: "rgb(74, 99, 165)" }}
                    >
                      Unlock value with 5 enrichment fields.{" "}
                    </h2>
                    <p
                      className="gr-text-9 gr-text-color-opacity"
                      style={{
                        lineHeight: "1.8",
                        letterSpacing: "-0.2px",
                        // fontSize: '0.9rem'
                      }}
                    >
                      Genify’s engine is designed to automatically categorize
                      credit card transactions into granular expense and income
                      categories. We also provide a merchant’s clean name, logo
                      and website, and calculate the carbon footprint as a
                      bonus.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="inner-banner pt-20 pb-11  border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Features
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <TransFeatures />
        </div>
        {/* <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Genify Dashboard
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <TransDashboard /> */}
        {/* <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Showcase
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <TransShowCase /> */}
        {/* <div
          className="inner-banner pt-29  bg-default-1 pb-25   border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        > */}
        {/* <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Why Genify?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <Differentiation /> */}
        {/* </div> */}
        <TxnDataUsecases />
        {/* <TxnDataDiff /> */}
        <WhyGenify />
        <div
          className="inner-banner pt-20 pb-20"
          style={{ backgroundColor: "rgb(245, 247, 250)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    How Does the Transaction Categorization API Work?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <TxnApiDemo /> */}
          <APIDemo selected="txn" exclusive />
        </div>
        {/* <TransCTA /> */}
      </PageWrapper>
    </>
  );
};

export default TxnCategorizer;

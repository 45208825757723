import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import iconF1 from "../assets/image/svg/database.svg";
import iconF2 from "../assets/image/svg/no-data.svg";
import iconF3 from "../assets/image/svg/multilingual.svg";
import iconF4 from "../assets/image/svg/self-improving.svg";
import iconF5 from "../assets/image/svg/loop.svg";
import realTimeIcon from "../assets/image/svg/realtime-icon.svg"
import iconF6 from "../assets/image/svg/custom.svg";
const TransFeatures = () => {
  return (
    <div
      className="feature-section pb-4 pb-lg-13 white"
      style={{ paddingTop: "" }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <div className="icon-wrap">
                  <img src={iconF1} alt="machine learning based" />
                </div>
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Source Agnostics</h5>
                  <p className="gr-text-9 white">
                    Enrich transactions from any source, including open banking.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF3} alt="multi lingual" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Multi-Lingual</h5>
                  <p className="gr-text-9 white">
                    Enrich transactions in English, العربية, русский, 中文 or any other language.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF5} alt="user feedback logo" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">User Feedback Loop</h5>
                  <p className="gr-text-9 white">
                  Allow your users to make corrections so Genify’s engine learns from them.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1100"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF2} alt="low to now data requirements" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">
                    Batch Calls
                  </h5>
                  <p className="gr-text-9 white">
                  Enrich a batch of transactions in a single API call for a convenient integration.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={realTimeIcon} alt="user feedback logo" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Low Latency</h5>
                  <p className="gr-text-9 white">
                  Enrich transactions in real time to provide insights instantly.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF4} alt="self improving" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Self-improving</h5>
                  <p className="gr-text-9 white">
                    Models are retrained at a set frequency to keep improving
                    over time.
                  </p>
                </div>
              </div>
            </div>
          </Col> */}
          
          {/* <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2100"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF6} alt="custom categories" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Custom categories</h5>
                  <p className="gr-text-9 white">
                    Your users can set custom expense and income categories.
                  </p>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default TransFeatures;
